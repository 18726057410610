/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/bootstrap@3.4.0/dist/js/bootstrap.min.js
 * - /npm/select2@4.0.3/dist/js/select2.min.js
 * - /npm/holderjs@2.9.3/holder.min.js
 * - /npm/jquery-validation@1.15.0/dist/jquery.validate.min.js
 * - /npm/respond.js@1.4.2/dest/respond.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
